<template>
    <main>
        <HeaderTab :title="$t('gynecologie.saisons')" />
        <div id="content">
            <div class="container-fluid">
                <div class="box">
                <CustomTable
                    id_table="season"
                    ref="table"
                    :items="filtredSeason"
                    :busy.sync="table_busy"
                    primaryKey="season_id"
                    :hide_if_empty="true"
                    :rawColumns="rawColumnNames"
                    :hrefsRoutes="config_table_hrefs"
                />
                <b-modal ref="modal" hide-footer>
                    <template v-slot:modal-title>
                        <template v-if="ajouter">
                            {{ $t("gynecologie.ajouter_saison") }}
                        </template>

                        <template v-if="modifier">
                            {{ $t("gynecologie.modifier_saison") }}
                        </template>
                    </template>
                    <SeasonAjout 
                        :add-or-edit-ready.sync="addOrEditReady"
                        ref="SeasonAjout"
                        :season_id="season_id"
                        :season="season"
                    />
                    <div class="text-center">
                    <b-button v-if="ajouter && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
                    <b-button v-if="modifier && addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
                    </div>
                </b-modal>
                <b-modal ref="modalDuplicate" hide-footer>
                    <template v-slot:modal-title>
                        <template>
                            {{ $t("gynecologie.dupliquer_saison") }}
                        </template>
                    </template>
                    <SeasonAjout 
                        :add-or-edit-ready.sync="addOrEditReady"
                        :season_id="season_id"
                        ref="SeasonDuplicate"
                    />
                    <div class="text-center">
                    <b-button v-if="addOrEditReady"  pill variant="primary" class="mt-3"  @click.prevent="checkFormDuplicate"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.dupliquer") }}</b-button>
                    </div>
                </b-modal>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js";
    import GynecologieMixin from "@/mixins/Gynecologie.js"

    export default {
        name: "season",
        mixins: [TableAction, Navigation, GynecologieMixin],
        data () {
            return {
                addOrEditReady: false,
                processing: false,
                ajouter: false,
                modifier: false,
                season_id: null,

                season: null,
                table_busy: true,
                rawColumnNames: ['season_start', 'season_end', 'season_default'],
                config_table_hrefs: {
                    'season_label': {
                        routeUniqueName: 'seasonFiche',
                        routeName: 'seasonFiche',
                        params: {
                            season_id: 'season_id'
                        }
                    }
                },
                events_tab: {
                    'TableAction::goToAddSeason': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditSeason': (params) => {
                        this.addOrEdit(params.season_id)
                    },
                    'TableAction::goToDuplicateSeason': (params) => {
                        this.duplicate(params.season_id)
                    },
                    'TableAction::goToDeleteSeason': (params) => {
                        this.deleteSeason(params.season_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadSeasonTable()
            },
            async loadSeasonTable() {
                this.table_busy = true
                let seasons = await this.loadSeasons()

                if(seasons) {
                    let temp = []
                    seasons.forEach((season) => {
                        season.season_start_date = season.season_start
                        season.season_end_date = season.season_end
                        season.season_default_format = this.getTrad("global.non")
                        if(season.season_default) {
                            season.season_default_format = this.getTrad("global.oui")
                        }
                        temp.push(season)
                    })

                    this.season = temp
                } else {
                    this.season = null
                }

                this.table_busy = false
            },
            async addOrEdit(season_id) {
                this.processing = false
                this.addOrEditReady = false
                if(season_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.season_id = season_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.season_id = null
                }

                this.$refs.modal.show()
            },
            async duplicate(season_id) {
                this.processing = false
                this.addOrEditReady = false
                if(season_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.season_id = season_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.season_id = null
                }

                this.$refs.modalDuplicate.show()
            },
            async checkForm() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.SeasonAjout.checkForm()
                    if(result) {
                        this.$refs.modal.hide()
                        this.loadSeasonTable()
                    } else {
                        this.processing = false
                    }
                }
            },
            async checkFormDuplicate() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.SeasonDuplicate.checkFormDuplicate()
                    if(result) {
                        await this.$sync.force(true)
                        this.$refs.modalDuplicate.hide()
                        this.loadSeasonTable()
                    } else {
                        this.processing = false
                    }
                }
            },
            async deleteSeason(season_id) {
                const season_mare = await this.loadSeasonMareBySeason(season_id)
                if(season_mare.length > 0) {
                    this.failureToast("gynecologie.cant_delete")
                    return false
                }
                this.deleteSeasonById(season_id)
                .then(() => {
                    this.successToast('toast.info_modif_succes')
                    this.loadSeasonTable()
                    this.$refs.table.resetCachedItems()
                })
                .catch((err) => {
                    console.log(err);
                    this.failureToast('toast.info_save_failed')
                })
            }
        },
        computed: {
            filtredSeason: function() {
                return this.season
            }
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            HeaderTab : () => import('@/components/HeaderTab'),
            SeasonAjout : () => import('@/components/Gynecologie/SeasonAjout'),
        }
    }
</script>
